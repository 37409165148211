<template>
  <div class="pricing-hero">
    <div class="container">
      <div class="row text-center">
        <h2 class="hero-title">
          Join the Movement Towards a Sustainable Future
        </h2>
        <div class="items hidden-on-mobile">
          <ul class="d-flex justify-content-center">
            <li class="each-item">
              <span class="icon icon-green"
                ><span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span></span
              >

              No credit card required.
            </li>
            <li class="each-item">
              <span class="icon icon-green"
                ><span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span></span
              >
              Cancel anytime.
            </li>
            <li class="each-item">
              <span class="icon icon-green"
                ><span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span></span
              >
              Free to try.
            </li>
          </ul>
        </div>
        <p class="description">
          The <span class="green-text"> Ultimate Dropshipping</span> Platform
          for <br />
          Vegan and Planet-Friendly Products!
        </p>
        <a
          class="hidden-on-web"
          href="https://calendly.com/vgang/vgang-for-suppliers"
        >
          <button
            class="d-flex align-items-center justify-content-center custom-button filled hero-button"
          >
            <img
              src="@/assets/icons/calendar_event.svg"
              style="margin-right: 5px"
              width="20px"
              height="20px"
              alt="shopify logo"
            />
            Schedule a call with us
          </button>
        </a>
        <div class="items hidden-on-web">
          <ul class="d-flex flex-column justify-content-center p-0">
            <li class="each-item">
              <span class="icon icon-green">
                <span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span>
              </span>
              No credit card required.
            </li>
            <li class="each-item">
              <span class="icon icon-green">
                <span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span>
              </span>
              Cancel anytime.
            </li>
            <li class="each-item">
              <span class="icon icon-green">
                <span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span>
              </span>
              Free to try.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
