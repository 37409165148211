<template>
  <div class="pricing-comment-component">
    <div class="container">
      <p class="description">
        The platform has been such a dream to find! It's been hard to find
        eco-friendly drop-shipping platforms/companies that:<br />
        1. have products you like <br />

        2. have amazing customer service <br />

        3. easy to use platform <br />
        I was able to get in touch with the team very soon after reaching out.
        They hopped on a call with me and helped me get set up. Really great
        find. I know it's just a matter of time before this best-kept secret
        blows up so get in early and support a great platform!
      </p>
      <div
        :class="{ 'adoratherapy-comment-component': adoratherapyStyle }"
        class="author-container d-flex gap-4"
      >
        <a class="mt-2" target="_blank" :href="url">
          <img
            src="https://runningwithwolves.shop/cdn/shop/files/RUNNING_WITH_WOLVES_1.png?v=1684595401"
            :alt="author"
            :title="author"
            class="profile"
          />
        </a>
        <div class="author d-flex flex-column justify-content-between">
          <img
            src="@/assets/icons/dark-starts.svg"
            alt="starts"
            title="starts"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
    },
    profile: {
      type: String,
    },
    author: {
      type: String,
    },
    url: {
      type: String,
    },
    adoratherapyStyle: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-comment-component {
  background: #edf7ee;
  padding: 75px;
  line-height: 28px;
  margin-bottom: 76px;
  margin-top: 76px;
  @include md {
    padding: 55px;
    margin-top: 28px;
  }
  @include sm {
    padding: 25px;
    margin-bottom: 55px;
  }
  .description {
    font-size: 20px;
    line-height: 44px;
    color: #475467;
    font-weight: 600;
    text-align: justify;
    @include md {
      font-size: 16px;
      line-height: 28px;
    }
    @include sm {
      font-size: 14px;
      line-height: 28px;
    }
  }
  .author-container {
    margin-top: 34px;
    .profile {
      width: 64px;
      height: 64px;
      @include md {
        width: 54px;
        height: 54px;
      }
    }
    .author-name {
      font-size: 16px;
      font-weight: 700;
      color: #475467;
    }
  }
  .author {
    img {
      width: 120px;
      height: 24px;
      @include md {
        width: 100px;
        height: 20px;
      }
    }
  }
}

.adoratherapy-comment-component {
  flex-direction: column-reverse !important;
  .profile {
    width: 180px !important;
    height: 32px !important;
  }
}
</style>
