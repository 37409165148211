<template>
  <div class="partnership">
    <Hero />
    <Plans />
    <Comment
      url="https://runningwithwolves.shop/"
      :adoratherapyStyle="true"
      class="adoratherapy-comment-component"
      author="runningwithwolves"
      profile="https://runningwithwolves.shop/cdn/shop/files/RUNNING_WITH_WOLVES_1.png?v=1684595401"
      description="The platform has been such a dream to find! It's been hard to find eco-friendly drop-shipping platforms/companies that:
      
         1. have products you like 
         2. have amazing customer service 
         3. easy to use platform
        I was able to get in touch with the team very soon after reaching out. They hopped on a call with me and helped me get set up. Really great find. I know it's just a matter of time before this best-kept secret blows up so get in early and support a great platform!"
    />
    <QaBaseComponent
      titleFor="Dropshippers"
      api="https://blog.vgang.io/wp-json/wp/v2/posts?categories=33&per_page=6&offset=0"
    />

    <JoinTheMovement
      url="https://calendly.com/vgang/vgang-for-dropshipers"
      :isScheduleCall="true"
    />
  </div>
</template>

<script>
import QaBaseComponent from "@/components/bases/QaBaseComponent.vue";
import Comment from "../components/pricing/Comment.vue";
import Hero from "../components/pricing/Hero.vue";
import JoinTheMovement from "@/components/bases/JoinTheMovement.vue";
import Plans from "../components/pricing/Plans.vue";
export default {
  name: "Pricing",
  components: {
    Hero,
    QaBaseComponent,
    JoinTheMovement,
    Comment,
    Plans,
  },
  metaInfo: {
    title:
      " VGANG: Premier Eco-friendly Dropshipping Platform - Connect with Top US, EU, and UK Suppliers",
    meta: [
      {
        name: "description",
        content: "Start for Free, Pay After You Sell",
      },
    ],
  },
};
</script>
