<template>
  <section class="plans-section">
    <div class="container">
      <div
        class="toggle-wrapper d-flex justify-content-center align-items-center"
      >
        <span
          :class="
            priceChangeValue ? ' toggle-label green-text' : 'toggle-label'
          "
          >Pay annually
        </span>
        <label class="switch">
          <input type="checkbox" v-model="priceChangeValue" />
          <span class="slider round"> </span>
        </label>

        <span
          :class="
            !priceChangeValue ? ' toggle-label green-text' : 'toggle-label'
          "
          >Pay monthly
        </span>
      </div>

      <div class="plans">
        <div
          :class="plan.name == 'Pro' ? 'green-bg' : ''"
          v-for="plan in plans"
          class="plan"
          :key="plan.name"
        >
          <p v-if="plan.name == 'Pro'" class="text-center best-value">
            BEST VALUE
          </p>
          <h2 class="plan-title">{{ plan.name }}</h2>
          <div class="price-section">
            <span v-if="plan.monthlyPrice == 0" class="price"> Free </span>
            <span v-if="plan.monthlyPrice !== 0" class="price">
              {{
                priceChangeValue
                  ? `${plan.currency_symbol}${plan.yearlyPrice / 12} /mo`
                  : `${plan.currency_symbol}${plan.monthlyPrice} /mo`
              }}
            </span>
            <div class="discount-container">
              <p v-if="plan.monthlyPrice < 0" class="discount">
                Save
                {{ calculateDiscount(plan.yearlyPrice, plan.monthlyPrice) }}%
                annually
              </p>
              <p
                v-if="plan.monthlyPrice > 0"
                :class="
                  plan.monthlyPrice > 0 && !priceChangeValue
                    ? 'discountDisabled'
                    : ''
                "
                class="discount"
              >
                Save
                {{ calculateDiscount(plan.yearlyPrice, plan.monthlyPrice) }}%
                annually
              </p>
            </div>
          </div>
          <p
            :class="plan.monthlyPrice == 0 ? 'newbie-desecration' : ''"
            class="desc"
          >
            {{ plan.description }}
          </p>
          <router-link
            v-if="plan.monthlyPrice == 0"
            to="/register/retailer"
            class="d-block text-center custom-button filled border-unset"
          >
            Get Started for Free
          </router-link>
          <router-link
            v-else
            to="/register/retailer"
            class="d-block text-center custom-button filled border-unset"
          >
            Start Now
          </router-link>

          <div
            v-for="feature in plan.support.split(',')"
            :key="feature"
            class="features d-flex flex-column gap-4"
          >
            <div class="feature d-flex align-items-center">
              <span class="icon icon-green">
                <span class="material-symbols-outlined icon icon-green">
                  check_circle
                </span>
              </span>

              <span class="title"> {{ feature }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import buttonLink from "@/components/bases/ButtonLink.vue";
export default {
  name: "Plans",
  data() {
    return {
      priceChangeValue: true,
      plans: [],
    };
  },
  components: {
    buttonLink,
  },
  computed: {
    splittedSupports() {
      const uniCornPlan = this.plans.find((plan) => plan.name === "Business");
      let splittedSupport = uniCornPlan.support.split(";");

      return splittedSupport;
    },
  },
  methods: {
    calculateDiscount(yearlyPrice, monthlyPrice) {
      const totalYearlyPrice = yearlyPrice;
      const totalMonthlyPrice = monthlyPrice * 12;

      const result =
        100 - Math.floor((totalYearlyPrice * 100) / totalMonthlyPrice);
      return result;
    },

    async getPlans() {
      const host = process.env.VUE_APP_BASEURL;
      const response = await axios
        .get(`${host}/public/plans`)
        .then((response) => {
          if (response.status == 200) {
            this.plans = response.data.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  async created() {
    await this.getPlans();
  },
};
</script>

<style lang="scss" scoped>
.plans-section {
  .icon-green {
    color: #008f4a;
    margin-right: 5px;
    font-size: 18px;
  }
  .green-bg {
    background-color: #008f4a !important;
    margin-top: 0 !important;

    padding-top: 28px !important;
    .plan-title,
    .price,
    .desc,
    .title {
      color: #fefefe !important;
    }
    .icon-green {
      color: #fff;
    }

    .custom-button {
      background-color: #fff;
      color: #008f4a !important;
      margin-top: 48px;
    }
    .features .feature img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(266deg) brightness(106%) contrast(105%) !important;
    }
    .discount {
      color: #fefefe !important;
    }
  }
  .best-value {
    background-color: #cde9ce;
    color: #008f4a;
    padding: 8px 21px;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 700;
  }
  .toggle-wrapper {
    margin: 55px 0;
    text-align: center;
    @include lg {
      margin: 34px 0;
    }
    .toggle-label {
      font-weight: 600;
      font-size: 16px;
      margin: 0 8px;
      @include lg {
        font-size: 14px;
      }
    }
  }
  .plans {
    display: grid;
    flex-direction: row;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    @include lg {
      grid-template-columns: 1fr 1fr;
      gap: 21px;
    }
    @include md {
      grid-template-columns: 1fr;
      gap: 21px;
    }

    .plan {
      // height: 738px;
      background-color: #fff;
      padding: 21px;
      border-radius: 14px;
      margin-top: 67px;
      @include lg {
        margin-top: 0;
        height: 701px;
      }
      @include md {
        width: 80%;
        margin: 0 auto;
        height: auto;
      }
      @include sm {
        width: 95%;
      }
    }
    @include lg {
      .plan:nth-child(2) {
        .custom-button {
          margin-top: 48px;
        }
      }
      .plan:nth-child(4) {
        padding-top: 77px;
      }
    }

    .plan-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #344054;
    }
    .desc {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: #667085;
      margin-bottom: 24px;
      margin-top: 34px;
      @include breakpoint(1387px) {
        font-size: 12px;
      }
      @include breakpoint(1172px) {
        font-size: 10px;
      }
      @include lg {
        font-size: 14px;
      }
    }
    .newbie-desecration {
      margin-top: 81px;
    }
    .price-section {
      .discountDisabled {
        opacity: 0;
      }
      .price {
        font-size: 32px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #344054;
        margin-bottom: 20px;
        margin-top: 14px;
        display: block;
      }
      .discount {
        color: #008f4a;
        font-size: 18px;
        font-weight: 600;
        transition: all 0.2s ease-in-out;
      }
    }
    .features {
      margin-top: 21px;
      .feature {
        img {
          filter: brightness(0) saturate(100%) invert(31%) sepia(78%)
            saturate(5223%) hue-rotate(147deg) brightness(96%) contrast(104%);
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
        .title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #667085;
        }
      }
    }

    .deactive {
      .icon-green {
        color: #98a2b3;
      }
      .title {
        color: #98a2b3 !important;
        text-decoration: line-through;
      }
    }
  }
}
.plans-section .green-bg .features .deactive img {
  filter: brightness(0) saturate(100%) invert(68%) sepia(22%) saturate(207%)
    hue-rotate(179deg) brightness(90%) contrast(90%) !important;
}
</style>
